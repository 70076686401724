@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes loaded {
    0% {
        opacity: 1;
        display: block;
    }

    99% {
        opacity: 0;
        display: block;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes spin {
    0% {
        transform: rotateZ(0deg);
    }

    60% {
        transform: rotateZ(360deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

@keyframes apri {
    from {
        max-height: 0px;
    }

    to {
        max-height: 500px;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes scroll {
    0% {
        opacity: 0;
        transform: translate(-50%, -50px);
    }

    50% {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    90% {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, 0);
    }
}