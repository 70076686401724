.anticon-eye {
    cursor: pointer;
}

.ant-input-number-input {
    height: 44px;
}

.ant-table-content {
    overflow: auto;
}

.ant-upload.ant-upload-select {
    width: 100%;
}

.ant-upload button {
    width: 100%;
}

.ant-notification-notice {
    z-index: 99999;
}

.ant-steps-icon i.anticon,
.info svg {
    vertical-align: 0;
}

.ant-pagination-item-link.ng-star-inserted {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-pagination-item.ng-star-inserted>a {
    text-decoration: none !important;
}

.spinning .ant-spin-container {
    padding: 0px;
}

.ant-spin-nested-loading>div>.ant-spin {
    max-height: 100vh !important;
}

.ant-checkbox+span {
    padding-right: 0px;
}

nz-form-label>label>span>i {
    vertical-align: -0.125em !important;
}

nz-form-label>label>span>i>svg {
    vertical-align: initial;
}

.spinner>div>.ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
}

.spinner-home>div>.ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    position: static;
}

.ant-back-top {
    z-index: 100;
}

.ant-empty-image {
    height: auto;
    width: 100%;
}

.ant-empty-image img {
    width: 100%;
}

nz-date-picker {
    padding: 10px 12px !important;
    width: 100%;
}

.data_membro div.ant-picker-input input.ant-input-disabled,
.data_nascita div.ant-picker-input input.ant-input-disabled {
    color: black !important;
}

.ant-picker.ant-picker-disabled,
.ant-picker-input>input[disabled] {
    color: black !important;
    background-color: white;
}

.ant-input-affix-wrapper {
    padding: 10px 12px;
}

@media screen and (max-width: 768px) {
    .ant-back-top {
        display: none !important;
    }

    .ant-form-item-label label {
        display: block !important;
        margin-bottom: 10px !important;
    }
}