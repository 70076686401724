.modal-body label {
    font-size: 0.875rem;
}

.modal-body input {
    padding: 10px 12px;
    width: 100%;
}

@media screen and (max-width: 960px) {
    .modal-body {
        padding: 40px 20px !important;
        width: 100% !important;
    }

}