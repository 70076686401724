.form-container {
    max-height: 100%;
    overflow: auto;
}

.input-container {
    margin-bottom: 12px;

    >label {
        font-size: 0.875rem;
        display: inline-block;
        margin-bottom: 6px;
    }

    >input {
        padding: 10px 12px;
        width: 100%;
    }
}

.cta-container {
    margin-top: 24px;

    >button {
        height: 52px;
        width: 100%;
        font-size: 14px;
    }
}