/* Bootstrap + Icons + Modal */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "/src/assets/css/modal.scss";

/* NgZorro */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "/src/assets/css/ng-zorro.scss";

/* Animazioni */
@import "/src/assets/css/animation.scss";

/* Form */
@import "/src/assets/css/form.scss";

:root {
  --dark: #000000 !important;
  --light: #eeeeee !important;
  --nav-height: 70px;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: var(--nav-height);
  font-family: 'Inter', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.noscroll {
  height: 100%;
  overflow: hidden;
}

.auth-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 15px;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.auth-wrapper div.form-container {
  animation: slideUp 0.2s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden {
  display: none !important;
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: visible;
}

.offcanvas-backdrop {
  z-index: 1000;
}

a:not([href]):not([class]) {
  text-decoration: underline;
}